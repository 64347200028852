import React from "react"
import PropTypes from "prop-types"
import Button from "./button-2"
import { ArrowRightIcon } from "@primer/octicons-react"
import { Link } from "gatsby"

export default function AssistantSection({
  icon,
  headerGray,
  headerOrange,
  title,
  content,
  buttonText,
  className,
}) {
  return (
    <div
      className={`flex flex-col items-start gap-[24px] md:gap-[32px] ${className}`}
    >
      <div className="flex flex-col items-start gap-0 md:gap-[16px] text-[0.875rem]/[1.5rem] xl:text-base/[1.5rem]">
        <div className="flex justify-items-center gap-[8px] font-bold font-mono">
          <span className="text-orange-200">{icon}</span>
          <span className="text-black-200">{headerGray}</span>
          <span className="text-orange-200">{headerOrange}</span>
        </div>
        <h1 className="text-white text-[1.5rem]/[2rem] md:text-[1.625rem]/[2rem] xl:text-[2rem]/[2.5rem] -tracking-[0.045rem] font-bold md:font-normal md:-tracking-[0.04875rem] xl:-tracking-[0.06rem]">
          {title}
        </h1>
        <p className="pt-[16px] pt-0 text-[0.75rem]/[1rem] -tracking-[0.018rem] tracking-0 md:text-base text-black-200 font-medium">
          {content}
        </p>
      </div>
      {buttonText ? (
        <Link to="/signup/?product=prod_pro">
          <Button
            icon={<ArrowRightIcon size={24} />}
            bgColor="bg-orange-200"
            textColor="text-black-500"
            iconColor="text-black-500"
          >
            {buttonText}
          </Button>
        </Link>
      ) : null}
    </div>
  )
}
AssistantSection.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  headerGray: PropTypes.string,
  headerOrange: PropTypes.string,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
}
