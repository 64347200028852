import React, { useEffect, useRef } from "react"
import dedent from "dedent"
import PropTypes from "prop-types"
import Prism from "prismjs"

export default function IndexSnippet({ code, language, sourcery }) {
  sourcery = sourcery || false

  const codeElem = useRef()
  useEffect(() => Prism.highlightElement(codeElem.current), [code, language])

  const containerBackground = sourcery
    ? "linear-gradient(232deg, rgba(59, 63, 70, 0.17) -0.41%, rgba(27, 32, 40, 0.17) 72.08%), rgba(28, 34, 46, 0.01)"
    : "linear-gradient(232deg, rgba(59, 63, 70, 0.17) -0.41%, rgba(27, 32, 40, 0.17) 72.08%), rgba(28, 34, 46, 0.01)"

  const padding = sourcery
    ? "pl-[8px] pr-[32px] md:px-[13.02px] xl:px-[22px] pt-[15px] md:pt-[21.55px] xl:pt-[37.5px] pb-[25px] md:pb-[24.24px] xl:pb-[30.5px]"
    : "pl-[10px] pr-[32px] md:px-[17.6px] xl:px-[22px] pt-[15px] md:pt-[29.9px] xl:pt-[37.5px] pb-[18.5px] md:pb-[29.6px] xl:pb-[30.5px]"

  return (
    <div
      className="p-[2px] md:p-[1.597px] xl:p-[2px] w-fit rounded-[11px] md:rounded-[8.783px] xl:rounded-[11px]"
      style={{
        background: sourcery
          ? "linear-gradient(60deg, #B55E16 0%, #3A363D)"
          : "linear-gradient(60deg, #4B526C 0%, #1C222E)",
      }}
    >
      <div className="bg-[color:#1C222E] rounded-[11px] md:rounded-[8.783px] xl:rounded-[11px]">
        <div
          style={{
            background: containerBackground,
            backdropFilter: "blur(21px)",
          }}
          className={`flex flex-row md:gap-[12.549px] gap-[16px] md:gap-0 xl:gap-5 rounded-[11px] md:rounded-[8.783px] xl:rounded-[11px] ${padding}`}
        >
          <div className="flex flex-col justify-between text-right text-[#2A313C] text-[0.625rem]/[0.9375rem] md:text-[0.59881rem]/[0.89819rem] xl:text-xs/[1.125rem] font-medium font-mono">
            {code.split("\n").map((_, index) => (
              <span key={index} className="p-0 m-0">
                {index + 1}
              </span>
            ))}
          </div>
          <pre className="!border-0 !bg-transparent !m-0 !p-0">
            <code
              ref={codeElem}
              className={`!bg-transparent !overflow-hidden !text-[0.625rem]/[0.9375rem] md:!text-[0.59881rem]/[0.89819rem] xl:!text-xs/[1.125rem] !text-[color:#98A3B4] !font-medium language-${language}`}
            >
              {dedent(code)}
            </code>
          </pre>
        </div>
      </div>
    </div>
  )
}
IndexSnippet.propTypes = {
  code: PropTypes.string,
  language: PropTypes.string,
  sourcery: PropTypes.bool,
}
