import React, { useEffect, useRef, useState } from "react"
import { ControlBar, Player } from "video-react"
import "video-react/dist/video-react.css"
import PropTypes from "prop-types"

import PlayIcon from "images/play.svg"
import PauseIcon from "images/pause.svg"
import CircleInformationIcon from "images/circle-information.svg"

export default function IndexVideoPlayer({ src, heading, description }) {
  const player = useRef()
  const [progress, setProgress] = useState(0)
  const [paused, setPaused] = useState(true)

  const handleStateChange = ({ currentTime, duration, ended, paused }) => {
    if (ended) {
      player.current.play()
    }

    setPaused(paused)
    setProgress(Math.floor((currentTime / duration) * 100))
  }

  const handlePause = () => {
    player.current.pause()
  }

  const handlePlay = () => {
    player.current.play()
  }

  useEffect(() => {
    player.current.subscribeToStateChange(handleStateChange)
  }, [])

  return (
    <div className="flex flex-col gap-0 relative rounded-[3.655px] md:rounded-[8.8px] xl:rounded-[11px]">
      <Player autoPlay muted playsInline src={src} ref={player}>
        <ControlBar disableDefaultControls />
      </Player>
      {paused ? (
        <div
          className="absolute top-0 left-0 w-full h-full flex flex-wrap place-content-center backdrop-blur-[21px] md:backdrop-blur-[17px]
          xl:backdrop-blur-[21px]"
          style={{
            background:
              "linear-gradient(232deg, rgba(59, 63, 70, 0.17) -0.41%, rgba(27, 32, 40, 0.17) 72.08%), rgba(28, 34, 46, 0.01)",
          }}
        >
          <div className="group w-[56px] md:w-[100px] xl:w-[122px] h-[56px] md:h-[100px] xl:h-[122px]">
            <img
              onClick={handlePlay}
              src={PlayIcon}
              className="invisible group-hover:visible w-full h-full"
            />
          </div>
          <div
            className="hidden md:block md:absolute md:bottom-[2.44px] xl:bottom-[5px] md:left-[3.27px] xl:left-[4px] flex flex-col
            md:px-[19.6px] xl:px-[24px] md:py-[13.1px] xl:py-[16px] md:gap-[8.17px] xl:gap-[10px] justify-center items-start
            md:rounded-[9px] xl:rounded-[11px] md:backdrop-blur-[11.84px] xl:backdrop-blur-[14.5px] bg-[#12171f99]"
          >
            <div className="flex md:gap-[8.17px] xl:gap-[10px] justify-center items-center">
              <img src={CircleInformationIcon} className="inline-block" />
              <span className="font-mono md:text-[0.75rem]/[1.125rem] xl:text-xs/[1.125rem] font-semibold text-black-200">
                {heading}
              </span>
            </div>
            <div
              className="md:text-[0.875rem]/[1.22538rem] xl:text-base/[1.5rem] font-medium md:-tracking-[0.021rem]
            xl:-tracking-[0.024rem] text-black-300"
            >
              {description}
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute top-0 left-0 w-full h-full flex flex-wrap place-content-center">
          <div className="group w-[56px] md:w-[100px] xl:w-[122px] h-[56px] md:h-[100px] xl:h-[122px]">
            <img
              onClick={handlePause}
              src={PauseIcon}
              className="invisible group-hover:visible w-full h-full"
            />
          </div>
        </div>
      )}
      <div className="h-[2px] md:h-[3px] xl:h-[5px] w-full rounded-[12px] absolute bottom-0 left-0">
        <div
          className="h-[2px] md:h-[3px] xl:h-[5px] bg-orange-300 rounded-[12px]"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  )
}
IndexVideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  heading: PropTypes.string,
  description: PropTypes.string,
}
