import React, { useState } from "react"
import PropTypes from "prop-types"
import IndexVideoPlayer from "./index-video-player"

export default function IndexTabs({ tabs }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const handleTabClick = (clickedTabIndex) => setActiveTabIndex(clickedTabIndex)

  return (
    <div className="flex flex-col gap-0 rounded-b-xl overflow-hidden w-full">
      <ul className="flex gap-0 items-stretch justify-items-stretch">
        {tabs.map((tab, index) => {
          const isTabActive = index === activeTabIndex
          const borderWidth = isTabActive
            ? "border-2 md:border-[1.634px] xl:border-2"
            : "border-[legnth:3px] md:border-[2.451px] xl:border-[length:3px]"
          const iconColor = isTabActive ? "fill-white" : "fill-black-300"
          return (
            <li
              onClick={() => handleTabClick(index)}
              key={tab.name}
              className={`flex gap-[4px] md:gap-[6.535px] xl:gap-[8px] md:py-[9.8px] xl:py-[12px] px-[10px] md:px-[13.1px] xl:px-[16px]
              cursor-pointer justify-center items-center rounded-t-xl ${borderWidth} border-b-0 border-s border-e
              border-solid border-[color:#1C222E] text-white font-sm text-[0.75rem]/[2.04225rem] xl:text-base/[2.5rem]
              md:-tracking-[0.0225rem] xl:-tracking-[0.03rem] md:backdrop-blur-[4px] xl:backdrop-blur-[5px]`}
              style={{
                background: isTabActive
                  ? "linear-gradient(0deg, #CC691E 0%, #CC691E 100%), rgba(21, 26, 34, 0.01)"
                  : undefined,
              }}
            >
              <span className={iconColor}>{tab.icon}</span>
              {tab.name}
            </li>
          )
        })}
      </ul>
      <div className="shrink-0">
        <IndexVideoPlayer
          src={tabs[activeTabIndex].video}
          heading={tabs[activeTabIndex].videoHeading}
          description={tabs[activeTabIndex].videoDescription}
        />
      </div>
    </div>
  )
}
IndexTabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.number,
}
