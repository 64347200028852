import React from "react"
import PropType from "prop-types"

const variants = {
  "large-gray-ltr": {
    gradientClass: "linear-gradient(60deg, #4B526C 0%, #1C222E)", //, #151921',
  },
  "large-gray-rtl": {
    gradientClass: "linear-gradient(-60deg, #4B526C 0%, #1C222E)", //, #151921',
  },
  "large-orange-ltr": {
    gradientClass: "linear-gradient(60deg, #B55E16 0%, #3A363D)", // , #3A363D',
  },
  "large-orange-rtl": {
    gradientClass: "linear-gradient(-60deg, #B55E16 0%, #3A363D)", // , #3A363D',
  },
  "small-gray-ltr": {
    gradientClass: "linear-gradient(30deg, #4B526C 0%, #1C222E)", //, #151921',
  },
  "small-gray-rtl": {
    gradientClass: "linear-gradient(-30deg, #4B526C 0%, #1C222E)", //, #151921',
  },
  "small-orange-ltr": {
    gradientClass: "linear-gradient(30deg, #B55E16 0%, #3A363D)", // , #3A363D',
  },
  "small-orange-rtl": {
    gradientClass: "linear-gradient(-30deg, #B55E16 0%, #3A363D)", // , #3A363D',
  },
}

export default function IndexHighlightedContainer({
  children,
  orange,
  small,
  rightToLeft,
}) {
  orange = orange || false
  small = small || false
  rightToLeft = rightToLeft || false

  const variant =
    variants[
      `${small ? "small" : "large"}-${orange ? "orange" : "gray"}-${
        rightToLeft ? "rtl" : "ltr"
      }`
    ]

  return (
    <div
      className={`z-10 p-[2px] md:p-[1.6px] xl:p-[2px] rounded-[11px] md:rounded-[8.783px] xl:rounded-[11px] overflow-hidden self-start`}
      style={{ background: variant.gradientClass }}
    >
      <div
        className={`bg-[#1C222E] rounded-[11px] md:rounded-[8.783px] xl:rounded-[11px] self-start`}
      >
        <div
          className="flex rounded-[11px] md:rounded-[8.783px] xl:rounded-[11px] p-[16px]
              md:pt-[15.17px] xl:pt-[19px] md:pb-[17.51px] xl:pb-[21px] md:pl-[21.539px] xl:pl-[27px] md:pr-[17.051px]
              xl:pr-[21.4px] text-black-300 text-[0.625rem]/[0.9375rem] md:text-[0.59881rem]/[0.89819rem] xl:text-xs/[1.125rem]
              font-semibold font-mono self-start backdrop-blur-[10px] md:backdrop-blur-[8px] xl:backdrop-blur-[10px]"
          style={{
            background:
              "linear-gradient(232deg, rgba(59, 63, 70, 0.17) -0.41%, rgba(27, 32, 40, 0.17) 72.08%), rgba(28, 34, 46, 0.28)",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
IndexHighlightedContainer.propTypes = {
  children: PropType.node.isRequired,
  orange: PropType.bool,
  small: PropType.bool,
  rightToLeft: PropType.bool,
}
