import React from "react"
import PropTypes from "prop-types"

export default function IndexBottomTestimonialCard({
  children,
  name,
  position,
}) {
  return (
    <div className="bg-black-400 rounded-[2px] p-[40px] flex flex-col gap-[40px] items-start w-[504px] shrink-0 me-[16px]">
      <div className="text-black-100 text-xl font-medium leading-[1.875rem] -tracking-[0.0375rem]">
        {children}
      </div>
      <div className="flex gap-[20px]">
        <div className="flex gap-0"></div>
        <div className="flex flex-col gap-[4px] items-start font-bold">
          <span className="text-xl text-white -tracking-[0.0625rem]">
            {name}
          </span>
          <span className="text-base text-black-200">{position}</span>
        </div>
      </div>
    </div>
  )
}
IndexBottomTestimonialCard.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  position: PropTypes.string,
}
