import React from "react"
import { Link } from "gatsby"
import {
  HeartIcon,
  RocketIcon,
  CheckCircleIcon,
  CpuIcon,
  BookIcon,
} from "@primer/octicons-react"

import Button from "components/button-2"
import SEO from "components/seo"
import ClientsCarousel from "components/clients"
import TypedText from "components/typedTexts"
import IndexTabs from "components/index-tabs"
import AssistantSection from "components/assistant-section"
import IndexSnippet from "components/index-snippet"
import IndexBottomTestimonialCard from "components/index-bottom-testimonial-card"
import IndexTopTestimonialCard from "components/index-top-testimonial-card"

import SubFooter from "components/sub-footer"
import InitialCode from "images/initial-code.svg"
import InitialCodeMd from "images/initial-code-md.svg"
import Top from "images/top.svg"
import TopMobile from "images/top-mobile.svg"
import Middle from "images/middle.svg"
import MiddleMobile from "images/middle-mobile.svg"
import Bottom from "images/bottom.svg"
import BottomMobile from "images/bottom-mobile.svg"
import AiBox from "images/ai-box.svg"
import AiBoxMobileTop from "images/AI-box-mobile-top.svg"
import AiBoxMobile from "images/AI-box-mobile.svg"
import VerticalLine from "images/vertical-line.svg"
import VerticalLineMd from "images/vertical-line-md.svg"
import VerticalLineMobile1 from "images/vertical-line-mobile-1.svg"
import VerticalLineMobile2 from "images/vertical-line-mobile-2.svg"
import VerticalLineMoblie3 from "images/vertical-line-mobile-3.svg"
import Pen from "images/pen.svg"
import SourceryPromptIcon from "images/sourcery-prompt-icon.svg"
import Label from "images/label.svg"
import VerticalLine2 from "images/vertical-line-2.svg"
import VerticalLineMobile4 from "images/vertical-line-mobile-4.svg"
import VerticalLineMobile5 from "images/vertical-line-mobile-5.svg"
import VerticalLineMobile6 from "images/vertical-line-mobile-6.svg"
import VerticalLineMobile7 from "images/vertical-line-mobile-7.svg"
import VerticalLineMobile8 from "images/vertical-line-mobile-8.svg"
import VerticalLineMobile9 from "images/vertical-line-mobile-9.svg"
import VerticalLineMobile10 from "images/vertical-line-mobile-10.svg"
import VerticalLineMobile11 from "images/vertical-line-mobile-11.svg"
import Explain from "images/explain.svg"
import Connector from "images/connector.svg"
import Connector2 from "images/connector-2.svg"
import Connector3 from "images/connector-3.svg"
import Connector4 from "images/connector-4.svg"
import Connector5 from "images/connector-5.svg"
import ImprovingIcon from "images/improving-icon.svg"
import Wand from "images/wand.svg"
import Compass from "images/compass.svg"
import PenOrange from "images/pen-orange.svg"
import DocumentCheck from "images/document-check.svg"
import CloudDownload from "images/cloud-download.svg"
import EditAlt from "images/edit-alt.svg"
import Repeat from "images/repeat.svg"
import Document from "images/document.svg"

import GenerateTestsVideo from "videos/generate_tests.mp4"
import ReviewCodeVideo from "videos/review_code.mp4"
import ExplainCodeVideo from "videos/explain_code.mp4"
import GenerateDocstrings from "videos/generate_docstrings.mp4"
import Ticker from "framer-motion-ticker"
import IndexPrompt from "components/index-prompt"
import IndexHighlightedContainer from "components/index-highlighted-container"

const CodingAssistantPage = () => {
  const highlights = [
    {
      icon: <img src={Wand} className="inline-block" />,
      headerGray: "from",
      headerOrange: "pair_programmer import Expert",
      title: "Answers to all of your coding questions",
      content:
        "Sourcery is your ever-present pair programmer. Whether you're learning a new API, deciding the optimal location for a new method, or need instant new code, Sourcery provides immediate and precise answers.",
      buttonText: "Get started",
    },
    {
      icon: <img src={Compass} className="inline-block" />,
      headerGray: "while",
      headerOrange: "exploring: knowledge.grow()",
      title:
        "Navigate and understand new code bases, legacy code, and new concepts",
      content:
        "Venturing into unfamiliar code? Sourcery is your navigator through new projects, legacy systems, or unfamiliar languages. Understand complex code instantly and get best-practice tips to sharpen your programming.",
      buttonText: "Start exploring",
    },
    {
      icon: <CheckCircleIcon size={24} />,
      headerGray: "if",
      headerOrange: "task.isrepetitive(): task.automate()",
      title: "Automate away repetitive work",
      content:
        "As a developer, repetitive tasks like writing tests, crafting docstrings, reviewing, and refactoring code are common. Sourcery streamlines these with a single click",
      buttonText: "Start automating",
    },
    {
      icon: <img src={PenOrange} className="inline-block text-orange-200" />,
      headerGray: "await",
      headerOrange: "instant_feedback()",
      title: "Accelerate Your Review Process",
      content:
        "Whether you're finalizing a branch or actively coding, Sourcery provides real-time, near-human review insights. Enjoy immediate feedback, reduce delays, and maintain peak code quality.",
      buttonText: "Start reviewing",
    },
  ]

  return (
    <div>
      <SEO
        title="Sourcery | Automatically Improve Code Quality"
        description="Sourcery instantly refactors your code. Try Sourcery for free to improve the quality of your code and speed up your team's development."
        keywords={[
          `sourcery`,
          `refactoring`,
          `developer tools`,
          `python`,
          "javascript",
          "typescript",
          `ai`,
        ]}
      />

      <div className="bg-black-500 overflow-hidden">
        {/* grid start */}
        <div
          className="max-w-[347px] md:max-w-[928px] xl:max-w-[1248px] mx-auto pt-[88px] md:pt-[96px] pb-[62px] md:pb-[80px] xl:pb-[148px] grid grid-cols-[100%] md:grid-cols-12
          md:grid-rows-[165px_80px_179px_102px_183px_42px_331px_10px_92px_77px_350px_543px_94px_355px_123px_205px_132px_116px_555px_157px_429px_630px_156px_100px]
          xl:grid-rows-[271px_110px_190px_121px_295px_52px_281px_93px_115px_95px_400px_765px_94px_317px_155px_268px_161px_142px_602px_154px_482px_630px_156px_376px]"
        >
          {/* Your Pair... start */}
          <div className="inline-block col-[1_/_2] md:col-[1_/_13] row-start-2 md:row-start-1 xl:pt-[86.9px] md:pt-[26.73px]">
            <div
              className="w-full flex flex-col px-[10px] md:px-0 sm:gap-4 text-[2rem]/[2.5rem] md:text-[3.5rem]/[4.04881rem] xl:text-7xl/[5.5rem] text-left
              text-black-100 font-medium -tracking-[0.06rem] md:-tracking-[0.105rem] xl:-tracking-[0.135rem]"
            >
              <h1>Your Pair Programmer</h1>
              <h1 className="flex flex-row gap-x-[10px] md:gap-x-6">
                for
                <div className="bg-black-400 rounded-[10px]">
                  <TypedText
                    textArray={[
                      "quicker code reviews",
                      "faster development",
                      "instant tests",
                    ]}
                    id="home"
                    className="md:h-[3.5rem] xl:h-[4.5rem]"
                  />
                </div>
              </h1>
            </div>
          </div>
          {/* Your Pair... end */}
          {/* Sourcery subheader start */}
          <div className="inline-block col-[1_/_2] px-[10px] md:px-0 pt-[16px] md:pt-0 md:col-start-1 md:col-span-6 row-start-3 md:row-start-2 xl:pt-[49.53px] md:pt-[35.95px]">
            <p className="text-base md:text-base/[1.38031rem] xl:text-[1.25rem]/[1.875rem] -tracking-[0.03rem] xl:-tracking-[0.0375rem] text-left text-black-200 font-medium">
              Code effortlessly with Sourcery as your partner at every stage of
              the development lifecycle.
            </p>
          </div>
          {/* Sourcery subheader end */}
          {/* Customers banner start */}
          <div className="inline-block col-[1_/_2] md:col-start-1 md:col-span-5 row-start-4 md:row-start-3 pt-[26px] md:pt-[66.51px]">
            <div className="py-[12px] px-[10px] md:px-0 md:pt-[20.39px] md:pb-7">
              <ClientsCarousel />
            </div>
          </div>
          {/* Customers banner end */}
          {/* Explore/Trial buttons start */}
          <div className="inline-block col-[1_/_2] md:col-[1_/_7] row-start-5 md:row-start-4 pt-[32px] md:pt-[53.75px] xl:pt-[73.02px]">
            <div className="flex md:gap-x-6 gap-[12px] px-[10px] md:px-0">
              <Link to="/signup/?product=prod_pro">
                <Button
                  icon={<RocketIcon size={24} />}
                  bgColor="bg-orange-200"
                  textColor="text-black-500"
                  iconColor="text-black-500"
                >
                  Get Started
                </Button>
              </Link>
            </div>
          </div>
          {/* Explore/Trial buttons end */}
          {/* Top IDE graphic start */}
          <div className="relative col-[1_/_2] h-[300px] md:h-auto inline-block md:col-[6_/_-1] row-start-1 md:row-[1/_7] relative">
            <object
              data={Top}
              className="hidden md:block md:-top-[131.57px] xl:-top-[163.46px] md:w-[1342px] xl:w-[1824px] md:h-[903px] xl:h-[1235px] absolute"
            ></object>
            <object
              data={TopMobile}
              className="md:hidden ml-[calc(50%_-_276px)] -mt-[100px] w-[857px] h-[576.5px]"
            ></object>
            <div
              className="hidden md:flex absolute w-[263px] h-[263px] xl:w-[363px] xl:h-[363px] backdrop-blur-[18px] rounded-[50%] top-[503px] xl:top-[705px] md:left-[calc(41.66%_-_191px)] xl:left-[calc(41.66%_-_263px)] place-content-center"
              style={{
                background:
                  "linear-gradient(90deg, rgb(54 58 65 / 23%), rgb(54 58 65 / 0%)) rgb(21 26 33 / 1%)",
                flexWrap: "wrap",
                opacity: 0.115,
              }}
            >
              <div
                className="w-[93px] h-[93px] xl:w-[133px] xl:h-[133px] backdrop-blur-[18px] rounded-[50%]"
                style={{
                  background:
                    "linear-gradient(rgb(204, 105, 30) 0%, rgba(204, 105, 30, 0.55) 8%, rgba(204, 105, 30, 0.06) 28%, rgba(204, 105, 30, 0) 100%)",
                  opacity: 0.88,
                }}
              >
                <div
                  className="w-[93px] h-[93px] xl:w-[133px] xl:h-[133px] backdrop-blur-[18px] rounded-[50%]"
                  style={{
                    background:
                      "radial-gradient(circle, rgb(34, 38, 47) 0%, rgba(87, 92, 115, 0.98) 39%, rgb(66, 71, 88) 51%, rgba(33, 38, 46, 0.27) 63%, rgba(33, 38, 46, 0) 100%)",
                  }}
                >
                  <div
                    className="w-[90px] h-[90px] xl:w-[130px] xl:h-[130px] bg-[#ffffff01] backdrop-blur-[25px] rounded-[50%]"
                    style={{
                      boxShadow: "rgba(247, 147, 30, 0.46) 0 0 98px 20px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {/* Top IDE graphic end */}

          {/* Vertical line start */}
          <div className="hidden md:inline-block col-[1_/_2] md:col-[6_/_10] row-start-5 md:row-[6_/_-1] z-[1]">
            <img
              src={VerticalLineMd}
              className="hidden md:max-xl:block md:ml-[163px] md:mt-[27px] md:h-[925px] z-0"
            />
            <img
              src={VerticalLine}
              className="hidden xl:block xl:ml-[218px] xl:mt-[37px] z-0"
            />
          </div>
          {/* Vertical line end */}

          {/* Vertical line mobile 1 start */}
          <div className="md:hidden col-[1_/_2] row-[6_/_7] md:row-[1_/_2]">
            <div className="flex place-content-center pt-[38px] pb-[21px]">
              <img src={VerticalLineMobile1} />
            </div>
          </div>
          {/* Vertical line mobile 1 end}

          {/* Vertical line mobile 2 start */}
          <div className="md:hidden col-[1_/_2] row-[7_/_9] md:row-[1_/_2]">
            <div className="flex place-content-center pt-[260px]">
              <img src={VerticalLineMobile2} className="z-20" />
            </div>
          </div>
          {/* Vertical line mobile 2 end}

          {/* from pair_programmer... start */}
          <div className="inline-block col-[1_/_2] md:col-[1_/_5] row-[7_/_8] md:row-[5_/_8] md:pt-[135.25px] xl:pt-[138.84px]">
            <div
              id="features"
              className="pt-86px md:pt-[96px] px-[10px] md:px-0"
            >
              <AssistantSection
                icon={highlights[0].icon}
                headerGray={highlights[0].headerGray}
                headerOrange={highlights[0].headerOrange}
                title={highlights[0].title}
                content={highlights[0].content}
                buttonText={highlights[0].buttonText}
                className="md:w-[358px] xl:w-[416px]"
              />
            </div>
          </div>
          {/* from pair_programmer... end */}

          {/* Initial code prompt start */}
          <div className="z-10 inline-block col-[1_/_2] md:col-[6_/_13] row-[8_/_9] md:row-start-6 pt-[74.14px] md:pt-0 md:pl-[154px] xl:pl-[207px]">
            <div className="flex gap-0">
              <img
                className="hidden xl:block box-border z-10"
                src={InitialCode}
              />
              <img
                className="hidden md:max-xl:block box-border z-10"
                src={InitialCodeMd}
              />
              <div className="md:hidden flex place-content-center w-full">
                <img src={AiBoxMobileTop} className="md:hidden" />
              </div>
              <div
                className="hidden md:block md:py-[9.58px] md:px-[12.8px] md:rounded-[8.783px] xl:py-[12px] xl:px-[16px] xl:rounded-[11px]
                border-solid md:border-[1.597px] xl:border-2p border-[#363C50] text-black-300 md:text-[0.79844rem] xl:text-base
                font-bold md:-tracking-[0.02394rem] xl:-tracking-[0.03rem] md:backdrop-blur-[4px] xl:backdrop-blur-[5px]"
                style={{
                  background:
                    "radial-gradient(369.25% 283.96% at 15.78% 7.82%, rgba(52, 68, 126, 0.19) 0%, rgba(52, 68, 126, 0.00) 100%), rgba(21, 26, 34, 0.01)",
                }}
              >
                Initial Code
              </div>
            </div>
          </div>
          {/* Initial code prompt end */}

          {/* Vertical line mobile 3 start */}
          <div className="inline-block md:hidden col-[1_/_2] row-[8_/_100] md:row-[1_/_2]">
            <div className="pl-[20px] pt-[315px]">
              <img src={VerticalLineMoblie3} />
            </div>
          </div>
          {/* Vertical line mobile 3 end */}

          {/* Initial code snippet start */}
          <div className="z-10 col-[1_/_2] md:col-[6_/_13] row-[9_/_10] md:row-[7_/_9]">
            <div className="pl-[2px] md:pl-[15px] xl:pl-[30px] md:pt-[36.82px] xl:pt-[46.11px]">
              <IndexSnippet
                code={`
                class User:
                  def __init__(self, username, email, password):
                      self.username = username
                      self._hashed_password = self._hash_password(password)
                      self.is_authenticated = False

                  def _hash_password(self, password):
                      return hashlib.sha256(password.encode()).hexdigest()

                  def authenticate(self, password):
                      if self._hashed_password == self._hash_password(password):
                          self.is_authenticated = True`}
                language="python"
              />
            </div>
          </div>
          {/* Initial code snippet end */}
          {/* Can you rewrite prompt start */}
          <div className="z-10 inline-block col-[1_/_2] md:col-[6_/_13] row-[10_/_11] md:row-[9_/_10]">
            <div className="pl-[2px] md:pl-[142px] xl:pl-[192px] pt-[34px] md:pt-[27.95px] xl:pt-[35px]">
              <IndexPrompt icon={<img src={Pen} />} small>
                <div>
                  Could we improve this class by adding methods for changing the
                  password and logging out the user?
                </div>
              </IndexPrompt>
            </div>
          </div>
          {/* Can you rewrite prompt end */}
          {/* Rewrite prompt reply start */}
          <div className="z-10 inline-block col-[1_/_2] md:col-[1_/_13] row-[11_/_12] md:row-[10_/_11] relative">
            <div className="md:hidden pl-[2px] md:pl-[20px] pt-[16px]">
              <IndexPrompt icon={<img src={SourceryPromptIcon} />} orange small>
                <div>Sure! Here`s the updated code with the new methods:</div>
              </IndexPrompt>
            </div>
            <div className="hidden md:block md:pt-[12px] xl:pt-[15px] md:absolute md:left-[calc(41.66%_-_147px)] xl:left-[calc(41.66%_-_224px)]">
              <IndexPrompt
                icon={<img src={SourceryPromptIcon} />}
                orange
                rightToLeft
                small
              >
                <div>Sure! Here`s the updated code with the new methods:</div>
              </IndexPrompt>
            </div>
          </div>
          {/* Rewrite prompt reply end */}

          {/* Rewritten code snippet start */}
          <div className="z-10 inline-block col-[1_/_2] md:col-[6_/_13] row-[12_/_13] md:row-[11_/_12]">
            <div className="pl-[4px] md:pl-[131px] xl:pl-[172px] pt-[36px] md:pt-[25px] xl:pt-[35px] relative">
              <IndexSnippet
                code={`
                class User:
                  def __init__(self, username, email, password):
                      self.username = username
                      self._hashed_password = self._hash_password(password)
                      self.is_authenticated = False

                  def _hash_password(self, password):
                      return hashlib.sha256(password.encode()).hexdigest()

                  def change_password(self, old_password, new_password):
                    if self.authenticate(old_password):
                        self._hashed_password = self._hash_password(new_password)
                        return True
                    return False

                  def logout(self):
                      self.is_authenticated = False

                  def authenticate(self, password):
                      if self._hashed_password == self._hash_password(password):
                          self.is_authenticated = True`}
                language="python"
                sourcery
              />
            </div>
          </div>
          {/* Rewritten code snippet end */}
          {/* Second graphic start */}
          <div className="inline-block col-[1_/_2] md:col-[1_/_13] row-[13_/_14] md:row-[12_/_15]">
            <object
              data={Middle}
              className="hidden md:block md:w-[1458.47px] md:h-[947.14px] md:-mt-[100px] md:ml-[calc(41.66%_-_851px)]
              xl:w-[1824px] xl:h-[1184px] xl:-mt-[136px] xl:ml-[calc(41.66%_-_1050px)]"
            ></object>
            <object
              data={MiddleMobile}
              className="md:hidden w-[960px] h-[499px] -mt-[5px] -ml-[350px] -mb-[99px]"
            ></object>
          </div>
          {/* Second graphic end */}
          {/* while is_learning... start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_12] row-[14_/_15] md:row-[12_/_13]">
            <div className="px-[10px] md:pr-0 md:pl-[151.2px] xl:pl-[151.2px] md:pt-[260px] xl:pt-[365.43px]">
              <AssistantSection
                icon={highlights[1].icon}
                headerGray={highlights[1].headerGray}
                headerOrange={highlights[1].headerOrange}
                title={highlights[1].title}
                content={highlights[1].content}
                buttonText={highlights[1].buttonText}
                className="md:w-[358px] xl:w-[416px]"
              />
            </div>
          </div>
          {/* while is learning... end */}

          {/* Vertical line 2 start */}
          <div className="hidden md:inline-block col-[1_/_2] md:col-[6_/_7] md:row-[14_/_-1]">
            <img
              src={VerticalLine2}
              className="z-0 md:mt-[157px] xl:mt-[160px] md:ml-[63px] xl:ml-[95px]"
            />
          </div>
          {/* Vertical line 2 end */}

          {/* Vertical line mobile 4 start */}
          <div className="md:hidden col-[1_/_2] row-[15_/_16] md:row-[1_/_2]">
            <div className="pt-[11px] pl-[39px]">
              <img src={VerticalLineMobile4} />
            </div>
          </div>
          {/* Vertical line mobile 4 end */}

          {/* Explain code snippet start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_13] row-[16_/_17] md:row-[14_/_15]">
            <div className="flex flex-col gap-0 pl-[14px] md:pl-0 md:ml-[2px] xl:ml-[32px] md:mt-[69px] xl:mt-[14px]">
              <img src={Label} className="w-[22px] ml-[15px] md:ml-[54px]" />
              <IndexSnippet
                className="w-[506px]"
                code={`
                def change_password(self, old_password, new_password):
                  if self.authenticate(old_password):
                      self._hashed_password = self._hash_password(new_password)
                      return True
                  return False`}
                language="python"
              />
            </div>
          </div>
          {/* Explain code snippet end */}
          {/* if task.isRepetitive... start */}
          <div className="inline-block col-[1_/_2] md:col-[1_/_5] row-[17_/_18] md:row-[15_/_18]">
            <div className="px-[10px] md:pr-0 pt-[67px] md:pl-0 md:pt-[101px]">
              <AssistantSection
                icon={highlights[2].icon}
                headerGray={highlights[2].headerGray}
                headerOrange={highlights[2].headerOrange}
                title={highlights[2].title}
                content={highlights[2].content}
                buttonText={highlights[2].buttonText}
                className="md:w-[363px] xl:w-[416px]"
              />
            </div>
          </div>
          {/* if task.isRepetitive... end */}

          {/* Vertical line mobile 6 start */}
          <div className="md:hidden col-[1_/_2] row-[18_/_20] md:row-[1_/_2]">
            <div className="pt-[85px] pl-[37px]">
              <img src={VerticalLineMobile6} />
            </div>
          </div>
          {/* Vertical line mobile 6 end */}

          {/* Explain code prompt start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_13] row-[18_/_19] md:row-[15_/_16]">
            <div className="pl-[18px] pt-[60px] md:pl-0 md:ml-[43px] md:pt-[82.36px] xl:ml-[69px] xl:pt-[103px] relative">
              <img
                src={VerticalLineMobile5}
                className="md:hidden absolute top-[20px] left-[36px]"
              />
              <IndexPrompt icon={<img src={Explain} />} small>
                <span>Explain Code</span>
              </IndexPrompt>
            </div>
          </div>
          {/* Explain code prompt end */}
          {/* Explain code response start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_13] row-[19_/_20] md:row-[16_/_17]">
            <div className="flex gap-0 mt-[16px] md:mt-[36px] xl:mt-[45.01px] ml-[18px] md:ml-[43px] xl:ml-[70px]">
              <IndexPrompt icon={<img src={SourceryPromptIcon} />} orange>
                <div className="flex flex-col">
                  <span className="text-[color:#F1F2F5]">What?</span>
                  <div className="md:hidden">
                    This method,{" "}
                    <span className="text-[color:#F97583]">
                      change_password
                    </span>{" "}
                    takes
                    <br />
                    <span className="text-[color:#F97583]">old_password</span>,
                    and{" "}
                    <span className="text-[color:#F97583]">new_password</span>
                    as arguments. It verifies the{" "}
                    <span className="text-[color:#F97583]">old_password</span> ,
                    updates the{" "}
                    <span className="text-[color:#F97583]">
                      _hashed_password
                    </span>{" "}
                    with the{" "}
                    <span className="text-[color:#F97583]">
                      new_password&#39;s
                    </span>{" "}
                    hash if True. Otherwise, it returns False.
                  </div>
                  <div className="hidden md:block">
                    This method,{" "}
                    <span className="text-[color:#F97583]">
                      change_password
                    </span>{" "}
                    takes
                    <br />
                    <span className="text-[color:#F97583]">old_password</span>,
                    and{" "}
                    <span className="text-[color:#F97583]">new_password</span>
                    as arguments. It verifies the{" "}
                    <span className="text-[color:#F97583]">old_password</span> ,
                    updates the{" "}
                    <span className="text-[color:#F97583]">
                      _hashed_password
                    </span>{" "}
                    with the{" "}
                    <span className="text-[color:#F97583]">
                      new_password&#39;s
                    </span>{" "}
                    hash if True. Otherwise, it returns False.
                  </div>
                </div>
              </IndexPrompt>
            </div>
          </div>
          {/* Explain code response end */}

          {/* Connector start */}
          <div className="hidden md:inline-block col-[1_/_2] md:col-[6_/_7] md:row-[16_/_-1]">
            <img
              src={Connector}
              className="z-0 md:mt-[74px] xl:mt-[88px] md:ml-[63px] xl:ml-[96px]"
            />
          </div>
          {/* Connector end */}

          {/* Connector 2 start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_7] row-[20_/_21] md:row-[17_/_-1]">
            <img
              src={Connector2}
              className="z-0 -mt-[1px] md:mt-[38px] xl:mt-[44px] ml-[25px] md:ml-[52px] xl:ml-[85px]"
            />
          </div>
          {/* Connector 2 end */}

          {/* Vertical line mobile 7 start */}
          <div className="md:hidden col-[1_/_2] row-[21_/_33] md:row-[1_/_2]">
            <div className="pt-[22px] pl-[37px]">
              <img src={VerticalLineMobile7} />
            </div>
          </div>
          {/* Vertical line mobile 7 end */}

          {/* Generate docstrings prompt start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_13] row-[21_/_22] md:row-[17_/_18]">
            <div className="-mt-[6px] pl-[19px] md:mt-0 md:pl-[45px] md:pt-[83px] xl:pl-[70px] xl:pt-[99px]">
              <IndexPrompt icon={<img src={Pen} />} small>
                <span className="text-white">Generate docstrings</span>
              </IndexPrompt>
            </div>
          </div>
          {/* Generate docstrings prompt end */}

          {/* Connector 3 start */}
          <div className="hidden md:inline-block col-[1_/_2] md:col-[6_/_7] md:row-[17_/_-1]">
            <img
              src={Connector3}
              className="z-0 md:ml-[64px] xl:ml-[96px] md:mt-[107px] xl:mt-[130px]"
            />
          </div>
          {/* Connector 3 end */}

          {/* Docstrings response bubble start */}
          <div className="inline-block col-[1_/_2] md:col-[1_/_13] row-[22_/_23] md:row-[18_/_19] relative">
            <div className="hidden md:block md:absolute md:left-[calc(41.66%_-_154px)] xl:left-[calc(41.66%_-_179px)] pt-[16px] md:pt-[49.27px] xl:pt-[58px]">
              <IndexPrompt
                icon={<img src={SourceryPromptIcon} />}
                orange
                small
                rightToLeft
              >
                <div className="text-right">
                  <span>Absolutely!</span>
                  <div>
                    Here’s your <span className="text-white">docstring</span>:
                  </div>
                </div>
              </IndexPrompt>
            </div>
            <div className="md:hidden pl-[18px] pt-[16px]">
              <IndexPrompt icon={<img src={SourceryPromptIcon} />} orange small>
                <div>
                  <span>Absolutely!</span>
                  <div>
                    Here’s your <span className="text-white">docstring</span>:
                  </div>
                </div>
              </IndexPrompt>
            </div>
          </div>
          {/* Docstrings response bubble end */}
          {/* Connector 5 start */}
          <div className="hidden md:inline-block col-[1_/_2] md:col-[6_/_7] md:row-[20_/_-1] relative">
            <img
              src={Connector5}
              className="absolute z-0 md:top-[138px] xl:top-[138px] md:left-[64px] xl:left-[96px]"
            />
          </div>
          {/* Connector 5 end */}

          {/* Connector 4 start */}
          <div className="hidden md:inline-block col-[1_/_2] md:col-[6_/_7] md:row-[19_/_-1] relative">
            <img
              src={Connector4}
              className="absolute z-0 md:top-[460px] xl:top-[498px] md:left-[64px] xl:left-[96px]"
            />
          </div>
          {/* Connector 4 end */}

          {/* Vertical line mobile 8 start */}
          <div className="md:hidden col-[1_/_2] row-[24_/_26] md:row-[1_/_2]">
            <div className="pt-[400px] pl-[37px]">
              <img src={VerticalLineMobile8} />
            </div>
          </div>
          {/* Vertical line mobile 8 end */}

          {/* Dosctring code snippet start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_13] row-[24_/_25] md:row-[19_/_20]">
            <div className="pl-[16px] md:pl-[34px] xl:pl-[51px] pt-[18px] md:pt-[44.57px] xl:pt-[55px] relative">
              <IndexSnippet
                code={`
                def change_password(self, old_password, new_password):
                  """
                  Changes the user's password.
                  Args:
                      old_password (str): The old password to authenticate.
                      new_password (str): The new password to set.

                  Returns:
                      bool: True if the password is successfully changed, False otherwise.
                  """
                  if self.authenticate(old_password):
                      self._hashed_password = self._hash_password(new_password)
                      return True
                  return False`}
                language="python"
                sourcery
              />
            </div>
          </div>
          {/* Docstring code snippet end */}

          {/* Vertical line mobile 9 start */}
          <div className="md:hidden col-[1_/_2] row-[25_/_27] md:row-[1_/_2]">
            <div className="pt-[122px] pl-[37px]">
              <img src={VerticalLineMobile9} />
            </div>
          </div>
          {/* Vertical line mobile 9 end */}

          {/* Generate tests prompt start */}
          <div className="inline-block col-[1_/_2] md:col-[1_/_13] row-[25_/_26] md:row-[20_/_21] relative">
            <div className="hidden md:block md:absolute md:pt-[105px] md:left-[calc(41.66%_-_126px)] xl:pt-[96px] xl:left-[calc(39.66%_-_147px)]">
              <IndexPrompt icon={<img src={Pen} />} small rightToLeft>
                <span className="text-white">Generate tests</span>
              </IndexPrompt>
            </div>
            <div className="md:hidden pt-[91px] pl-[17px]">
              <IndexPrompt icon={<img src={Pen} />} small>
                <span className="text-white">Generate tests</span>
              </IndexPrompt>
            </div>
          </div>
          {/* Generate tests prompt end */}

          {/* Generate tests reply start */}
          <div className="z-10 inline-block col-[1_/_2] md:col-[6_/_13] row-[26_/_27] md:row-[21_/_22]">
            <div className="flex flex-col gap-0 pl-[18px] md:pl-[29px] xl:pl-[52px] pt-[56px] md:pt-[38px] xl:pt-[41px]">
              <div
                className="rounded-[50%] md:ml-[15px] xl:ml-[20px] p-[9.31px] md:p-[11.425px] xl:p-[13.5px] border-solid
                border-[2px] md:border-[2.4px] xl:border-[3px] border-orange-300 backdrop-blur-[4px] backdrop-blur-[5px]
                self-start"
                style={{
                  background:
                    "radial-gradient(363.55% 279.72% at 15.78% 13.43%, rgba(52, 68, 126, 0.19) 0%, rgba(52, 68, 126, 0.00) 100%), rgba(21, 26, 34, 0.01)",
                }}
              >
                <img
                  src={SourceryPromptIcon}
                  className="w-[16.42px] md:w-[18.2px] xl:w-[24px] h-[16.37px] md:h-[18.2px] xl:h-[24px]"
                />
              </div>
              <div
                className="w-[2px] md:w-[2.4px] xl:w-[3px] bg-orange-300 h-[17px] md:h-[34px] xl:h-[40px] ml-[19px] md:ml-[37px] xl:ml-[44px]
              self-start"
              ></div>
              <div className="relative md:pt-[5px]">
                <IndexSnippet
                  code={`
                  import pytest
                  from demo import change_password

                  @pytest.mark.parametrize(
                      "password, old_password, new_password, expected_result,
                      [
                          ("password", "password", "new_password", True),
                          ("password", "wrong_password" , "new_password", False),
                          ...,
                      ]
                  )
                  def test_change_password(password, old_password, new_password, expected_result):
                      # Arrange
                      user = User("username", password)

                      # Act
                      result = user.change_password(old_password, new_password)

                      # Assert
                      assert result == expected_result`}
                  language="python"
                  sourcery
                />
              </div>
            </div>
          </div>
          {/* Generate tests reply end */}
          {/* Bottom graphic start */}
          <div className="relative col-[1_/_2] inline-block md:col-[1_/_13] row-[27_/_28] md:row-[22_/_23]">
            <object
              data={Bottom}
              className="hidden md:block md:w-[1429px] xl:w-[1824px] md:h-[928px] xl:h-[1184px] absolute md:-top-[183px] xl:-top-[233px]
              md:ml-[calc(41.66%_-_838px)] xl:ml-[calc(41.66%_-_1055px)]"
            ></object>
            <object
              data={BottomMobile}
              className="md:hidden w-[962px] h-[624px] -ml-[268px] -mt-[16px] -mb-[65px]"
            ></object>
          </div>
          {/* Bottom graphic end */}

          {/* Vertical line mobile 10 start */}
          <div className="md:hidden col-[1_/_2] row-[28_/_30] md:row-[1_/_2]">
            <div className="z-10 pt-[197px] flex justify-center relative z-10">
              <img src={VerticalLineMobile10} />
            </div>
          </div>
          {/* Vertical line mobile 10 end */}

          {/* AI box start */}
          <div className="inline-block col-[1_/_2] md:col-[5_/_9] row-[29_/_30] md:row-[22_/_25]">
            <div className="flex items-center justify-center relative w-full h-full">
              <object
                data={AiBox}
                className="hidden md:block md:absolute md:top-[319px] xl:top-[407px] md:w-[286.54px] xl:w-[367px] md:h-[343px] xl:h-[482px]
                md:left-[calc(41.66%_-_62px)] xl:left-[calc(41.66%_-_129px)]"
              ></object>
              <object
                data={AiBoxMobile}
                className="md:hidden top-[319px] w-[286.54px] h-[343px] ml-[1px]"
              ></object>
              <div
                className="w-[283px] h-[283px] md:w-[263px] md:h-[263px] xl:w-[363px] xl:h-[363px] backdrop-blur-[18px] rounded-[50%] absolute top-[24px] left-[32px] md:top-[370px] xl:top-[481px] flex md:left-[calc(41.66%_-_50px)] xl:left-[calc(41.66%_-_127px)] place-content-center"
                style={{
                  background:
                    "linear-gradient(90deg, rgb(54 58 65 / 23%), rgb(54 58 65 / 0%)) rgb(21 26 33 / 1%)",
                  flexWrap: "wrap",
                  opacity: 0.115,
                }}
              >
                <div
                  className="w-[123px] h-[123px] md:w-[93px] md:h-[93px] xl:w-[133px] xl:h-[133px] backdrop-blur-[18px] rounded-[50%]"
                  style={{
                    background:
                      "linear-gradient(rgb(204, 105, 30) 0%, rgba(204, 105, 30, 0.55) 8%, rgba(204, 105, 30, 0.06) 28%, rgba(204, 105, 30, 0) 100%)",
                    opacity: 0.88,
                  }}
                >
                  <div
                    className="w-[123px] h-[123px] md:w-[93px] md:h-[93px] xl:w-[133px] xl:h-[133px] backdrop-blur-[18px] rounded-[50%]"
                    style={{
                      background:
                        "radial-gradient(circle, rgb(34, 38, 47) 0%, rgba(87, 92, 115, 0.98) 39%, rgb(66, 71, 88) 51%, rgba(33, 38, 46, 0.27) 63%, rgba(33, 38, 46, 0) 100%)",
                    }}
                  >
                    <div
                      className="w-[120px] h-[120px] md:w-[90px] md:h-[90px] xl:w-[130px] xl:h-[130px] bg-[#ffffff01] backdrop-blur-[25px] rounded-[50%]"
                      style={{
                        boxShadow: "rgba(247, 147, 30, 0.46) 0 0 98px 20px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* AI box end */}
          {/* %timeit... start */}
          <div className="z-20 inline-block col-[1_/_2] md:col-[1_/_5] row-[28_/_29] md:row-[22_/_23]">
            <div className="px-[10px] md:px-0 md:pt-[560px] xl:pt-[625px] z-10">
              <AssistantSection
                icon={highlights[3].icon}
                headerGray={highlights[3].headerGray}
                headerOrange={highlights[3].headerOrange}
                title={highlights[3].title}
                content={highlights[3].content}
                buttonText={highlights[3].buttonText}
                className="md:w-[358px] xl:w-[416px]"
              />
            </div>
          </div>
          {/* %timeit... end */}

          {/* Vertical line mobile 11 start */}
          <div className="md:hidden col-[1_/_2] row-[29_/_31] md:row-[1_/_2]">
            <div className="z-10 pt-[250px] flex justify-center">
              <img src={VerticalLineMobile11} />
            </div>
          </div>
          {/* Vertical line mobile 11 end */}

          {/* Reviewing changes start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_13] row-[30_/_31] md:row-[22_/_23]">
            <div className="flex place-content-center md:place-content-start w-full md:w-auto md:pt-[435px] xl:pt-[572px] md:pl-[191px] xl:pl-[209px]">
              <IndexHighlightedContainer orange small>
                <span>Reviewing uncommitted changes on branch</span>
                <span className="text-orange-200">{`'magic-code'`}</span>
              </IndexHighlightedContainer>
            </div>
          </div>
          {/* Reviewing changes end */}
          {/* Summary start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_13] row-[31_/_32] md:row-[22_/_23]">
            <div className="flex place-content-center md:place-content-start w-full md:w-auto pt-[16px] md:pt-[500px] xl:pt-[655px] md:pl-[191px] xl:pl-[209px]">
              <IndexHighlightedContainer orange small>
                <div className="w-fit flex flex-col md:gap-0 xl:gap-[8px]">
                  <span className="text-orange-200">Summary:</span>
                  <span>
                    This PR expands the `User` class.
                    <br />
                    Specifically it introduces the ability for users
                    <br />
                    to change their password and log out.
                  </span>
                </div>
              </IndexHighlightedContainer>
            </div>
          </div>
          {/* Summary end */}
          {/* General suggestions start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_13] row-[32_/_33] md:row-[22_/_23]">
            <div className="flex place-content-center md:place-content-start w-full md:w-auto pt-[16px] md:pt-[636px] xl:pt-[855px] md:pl-[160px] xl:pl-[164px]">
              <IndexHighlightedContainer orange small>
                <div className="w-fit flex flex-col md:gap-0 xl:gap-[8px]">
                  <span className="text-orange-200">General Suggestions:</span>
                  <span className="text-[color:#79B8F5]">
                    Ensure that the password is stored in a database to maintain
                    <br />
                    the user&#39;s credentials across restarts of the
                    application
                  </span>
                </div>
              </IndexHighlightedContainer>
            </div>
          </div>
          {/* General suggestions end */}
          {/* Specific suggestions start */}
          <div className="inline-block col-[1_/_2] md:col-[6_/_13] flex row-[34_/_35] md:row-[22_/_23]">
            <div className="flex place-content-center md:place-content-start w-full md:w-auto pt-[16px] md:pl-[55px] md:pt-[732px] xl:pt-[985px]">
              <IndexHighlightedContainer orange small>
                <div className="w-fit flex flex-col mx:gap-0 xl:gap-[8px] text-[color:#B4CB59]">
                  <span className="text-orange-200">Specific suggestions:</span>
                  <div>
                    <span className="text-black-100 md:pl-[16px] xl:pl-[20px]">
                      Line 9:{" "}
                    </span>
                    <span>
                      This method hashes passwords securely using SHA-256.
                      <br />
                      It&#39;s private, which is good, but consider integrating
                      <br />a salt for even better security against rainbow
                      table attacks.
                    </span>
                  </div>
                  <div>
                    <span className="text-black-100 md:pl-[16px] xl:pl-[20px]">
                      Line 28:{" "}
                    </span>
                    <span>
                      Consider logging logout operations for monitoring.
                    </span>
                  </div>
                </div>
              </IndexHighlightedContainer>
            </div>
          </div>
          {/* Specific suggestions end */}
        </div>
        {/* grid end */}

        {/* results banner start */}
        <div className="max-w-[351px] md:max-w-[928px] xl:max-w-[1248px] mx-auto pb-[48px] md:pb-8 border-solid border-t border-black w-full">
          {/* example tabs start */}
          <div className="flex flex-col pt-[72px] md:pt-[80px] xl:pt-[115px] gap-[45px] md:gap-[56px] xl:gap-[67px] px-[10px] md:px-0">
            <AssistantSection
              icon={<CpuIcon />}
              headerGray="cat"
              headerOrange="examples.py"
              title="See what Sourcery can do"
              content="Discover how Sourcery can enhance your code, offer immediate feedback, and simplify code generation."
              className="md:w-[498px]"
            />
            <IndexTabs
              tabs={[
                {
                  name: "Generate Tests",
                  icon: (
                    <img
                      src={Repeat}
                      className="inline-block md:w-[19.61px] xl:w-[24px] md:h-[19.61px] xl:h-[24px]"
                    />
                  ),
                  video: GenerateTestsVideo,
                  videoHeading: "Writing unit tests with Sourcery",
                  videoDescription:
                    "Get the building blocks for full test coverage for your code",
                },
                {
                  name: "Review Code",
                  icon: (
                    <img
                      src={Pen}
                      className="inline-block md:w-[19.61px] xl:w-[24px] md:h-[19.61px] xl:h-[24px]"
                    />
                  ),
                  video: ReviewCodeVideo,
                  videoHeading: "Sourcery powered Code Review",
                  videoDescription:
                    "Sourcery can review your current work and give you feedback like a colleague would.",
                },
                {
                  name: "Explain Code",
                  icon: (
                    <>
                      <BookIcon
                        className="!hidden md:max-xl:!inline-block"
                        size={19.61}
                      />
                      <BookIcon
                        className="!hidden xl:!inline-block"
                        size={24}
                      />
                    </>
                  ),
                  video: ExplainCodeVideo,
                  videoHeading: "Understand your project with Sourcery",
                  videoDescription:
                    "When you dive into a new project you can have Sourcery explain odd or unusual sections of code so you can get up to speed faster",
                },
                {
                  name: "Write Docstrings",
                  icon: (
                    <img
                      src={Document}
                      className="inline-block md:w-[19.61px] xl:w-[24px] md:h-[19.61px] xl:h-[24px]"
                    />
                  ),
                  video: GenerateDocstrings,
                  videoHeading: "Docstrings on demand",
                  videoDescription:
                    "Let Sourcery write your docstrings for you so you can focus on more interesting problems",
                },
              ]}
            />
          </div>
          {/* example tabs end */}

          <div
            className="flex flex-col md:flex-row gap-[32px] md:gap-[113px] xl:gap-[136px] pt-[48px] md:pt-[133px] xl:pt-[111px] px-[10px] md:px-0
          font-mono font-medium"
          >
            <div className="flex flex-col text-blue-200 gap-[4px] md:gap-[11.33px] xl:gap-[14px] md:w-[239px] xl:w-[288px]">
              <img src={DocumentCheck} className="w-[24px]" />
              <p className="text-white text-[1.5rem] md:text-[2.5rem] xl:text-[length:3rem]">
                3,000,000+
              </p>
              <p className="md:text-xs uppercase font-bold xl:text-[length:0.875rem] md:tracking-[0.17694rem] xl:tracking-[0.21294rem]">
                Loc removed
              </p>
            </div>
            <div className="flex flex-col text-pink-200 gap-[4px] md:gap-[11.33px] xl:gap-[14px] md:w-[239px] xl:w-[288px]">
              <img src={CloudDownload} className="w-[24px]" />
              <p className="text-white text-[1.5rem] md:text-[2.5rem] xl:text-[length:3rem]">
                150,000+
              </p>
              <p className="md:text-xs uppercase font-bold xl:text-[length:0.875rem] md:tracking-[0.17694rem] xl:tracking-[0.21294rem]">
                Downloads
              </p>
            </div>
            <div className="flex flex-col text-purple-300 gap-[4px] md:gap-[11.33px] xl:gap-[14px] md:w-[239px] xl:w-[288px]">
              <img src={EditAlt} className="w-[24px]" />
              <p className="text-white text-[1.5rem] md:text-[2.5rem] xl:text-[length:3rem]">
                400,000+
              </p>
              <p className="md:text-xs uppercase font-bold xl:text-[length:0.875rem] md:tracking-[0.17694rem] xl:tracking-[0.21294rem]">
                Monthly Improvements
              </p>
            </div>
          </div>
        </div>
        {/* results banner end */}

        <div className="max-w-[351px] md:max-w-[928px] xl:max-w-[1248px] mx-auto pt-[48px] md:pt-[65px] px-[10px] md:px-0 border-solid border-t border-black">
          <div className="w-fit">
            <AssistantSection
              icon={<img src={ImprovingIcon} className="inline-block" />}
              headerGray="with "
              headerOrange="Sourcery(): development.accelerate()"
              title="Automate more, deliver faster, and continuously improve"
              buttonText="Start automating"
              className="md:w-[498px]"
            />
          </div>
        </div>

        {/* Testimonial */}
        <div className="md:pl-[14.4px] xl:pl-[18px] pt-[99px] md:pt-[128px] xl:pt-[105px]">
          <div className="flex flex-col md:gap-[52px] md:gap-[49px] xl:gap-[65px] mb-10">
            <div className="md:pb-[28px]">
              <Ticker duration={20}>
                <IndexTopTestimonialCard name={"Eric Fuenkes"}>
                  <p>
                    “Unit tests are awesome and so are the code reviews. They
                    both help me with things that are not deep work but still
                    deserve my attention; I don’t necessarily want to{" "}
                    <span className="text-purple-200">
                      spend hours writing tests or doing code review
                    </span>
                    , but I do want my attention brought to the most important
                    parts”
                  </p>
                </IndexTopTestimonialCard>
                <IndexTopTestimonialCard name={"Alwin Wan"}>
                  <p>
                    “<span className="text-purple-200">@SourceryAI</span> is
                    great! I never knew that #Python pathlib`s Path already
                    includes a .read_text() method which has context manager.”
                  </p>
                </IndexTopTestimonialCard>
                <IndexTopTestimonialCard name={"Simon"}>
                  <p>
                    “The inclusion of the Coding Assistant is next level
                    amazing. Just a couple of days with it and{" "}
                    <span className="text-purple-200">
                      my productivity is going through the roof
                    </span>
                    .
                  </p>
                </IndexTopTestimonialCard>
              </Ticker>
            </div>
            <div className="w-full md:w-auto flex items-center justify-start md:justify-center mx-auto px-7 py-8 bg-black-500 md:bg-transparent z-10">
              <div>
                <div className="text-pink-200 md:mb-[6.4px] xl:mb-2">
                  <HeartIcon />
                </div>

                <p
                  className="md:mb-[3.2px] xl:mb-2 font-mono font-bold text-black-200 md:text-[0.8rem]/[1.2rem]
                xl:text-base"
                >
                  git commit -m
                  <span className="text-pink-200"> “love”</span>
                </p>
                <p className="lg:max-w-[25rem] font-body md:text-[3.2rem]/[3.5rem] xl:text-[4rem]/[4.375rem] text-white">
                  Loved by developers
                </p>
              </div>
            </div>
            <div className="xl:pt-[32px]">
              <Ticker duration={20} direction={1}>
                <IndexBottomTestimonialCard name="Kevin Kirsh">
                  <p>
                    “Woah.{" "}
                    <span className="text-purple-200">@SourceryAI’s</span> new
                    contextlib example is the first refactoring I haven’t ever
                    seen before in the wild. That’s awesome. Keep up the great
                    work, this is extremely exciting and will help me recommend
                    it to senior devs not just more junior team members”
                  </p>
                </IndexBottomTestimonialCard>
                <IndexBottomTestimonialCard name="Ken">
                  <p>
                    “I’ve been testing out several AI tools and generally have
                    found it to be an awful experience and a net negative. I
                    keep trying though. BUT, I’ve been getting{" "}
                    <span className="text-purple-200">
                      real value from the Sourcery Assistant
                    </span>
                    .”
                  </p>
                </IndexBottomTestimonialCard>
                <IndexBottomTestimonialCard name="Nick Paloskov">
                  <p>
                    “I love pair programming but Sourcery can take over those
                    times I’m unavailable and my team still create{" "}
                    <span className="text-purple-200">great quality code</span>”
                  </p>
                </IndexBottomTestimonialCard>
                <IndexBottomTestimonialCard name="Dan">
                  <p>
                    “I’m loving the docstring generation with Sourcery, it is
                    very clean looking compared to other AI-based docstring
                    generators”
                  </p>
                </IndexBottomTestimonialCard>
              </Ticker>
            </div>
          </div>
          {/* start pricing */}
          {/*
          <div className="flex flex-col pt-[100px] md:pt-[86px] xl:pt-[120px] items-center">
            <div className="flex flex-col items-center">
              <div className="rotate-90 text-blue-200 mb-[8px]">
                <TagIcon size={24} />
              </div>
              <p className="font-mono font-bold text-base text-black-200 mb-[4px]">
                match typeOfUser:
              </p>
              <p className="md:text-[1.625rem]/[2.5rem] xl:text-[2rem]/[2.5rem] mb-[8px] -tracking-[0.06rem] font-bold text-[color:#F2F2F2]">
                Simple, flexible pricing
              </p>
              <p
                className="md:text-[0.875rem]/[1.5rem] xl:text-base text-blue-200 font-medium md:-tracking-[0.021rem]
              xl:-tracking-[0.024rem]"
              >
                Save up to <strong>20%</strong> with yearly subscription
              </p>
            </div>
            <Pricing />
          </div>
          */}
          {/* end pricing */}
        </div>
      </div>

      {/* sub footrt */}
      <SubFooter />
    </div>
  )
}

export default CodingAssistantPage
