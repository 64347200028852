import React from "react"
import PropTypes from "prop-types"
import IndexHighlightedContainer from "./index-highlighted-container"

const variants = {
  "large-gray-ltr": {
    backgroundClass: "bg-[#363C50]",
    borderClass: "border-[#363C50]",
  },
  "large-gray-rtl": {
    backgroundClass: "bg-[#363C50]",
    borderClass: "border-[#363C50]",
  },
  "large-orange-ltr": {
    backgroundClass: "bg-[#CC691E]",
    borderClass: "border-[#CC691E]",
  },
  "large-orange-rtl": {
    backgroundClass: "bg-[#CC691E]",
    borderClass: "border-[#CC691E]",
  },
  "small-gray-ltr": {
    backgroundClass: "bg-[#363C50]",
    borderClass: "border-[#363C50]",
  },
  "small-gray-rtl": {
    backgroundClass: "bg-[#363C50]",
    borderClass: "border-[#363C50]",
  },
  "small-orange-ltr": {
    backgroundClass: "bg-[#CC691E]",
    borderClass: "border-[#CC691E]",
  },
  "small-orange-rtl": {
    backgroundClass: "bg-[#CC691E]",
    borderClass: "border-[#CC691E]",
  },
}

export default function IndexPrompt({
  icon,
  children,
  rightToLeft,
  small,
  orange,
}) {
  rightToLeft = rightToLeft || false
  small = small || false
  orange = orange || false

  const variantId = `${small ? "large" : "small"}-${
    orange ? "orange" : "gray"
  }-${rightToLeft ? "rtl" : "ltr"}`
  const variant = variants[variantId]

  return (
    <div className={`flex gap-0 ${rightToLeft ? "flex-row-reverse" : ""}`}>
      <div
        className={`rounded-[50%] backdrop-blur-[5px] md:backdrop-blur-[4px] xl:backdrop-blur-[5px] p-[9.325px] md:p-[11px] xl:p-[13.5px] border-solid
        border-[2px] md:border-[length:2.395px] xl:border-[length:3px] flex ${
          small ? "place-items-center place-self-center" : "self-start"
        }
        ${variant.borderClass}`}
        style={{
          background:
            "radial-gradient(369.25% 283.96% at 15.78% 7.82%, rgba(52, 68, 126, 0.19) 0%, rgba(52, 68, 126, 0.00) 100%), rgba(21, 26, 34, 0.01)",
        }}
      >
        <div className="w-[16.42px] h-[16.37px] md:w-[19.16px] xl:w-[24px] md:h-[19.16px] xl:h-[24px]">
          {icon}
        </div>
      </div>
      <div
        className={`h-[2px] md:h-[2.395px] xl:h-[3px] w-[18px] md:w-[29px] xl:w-[37px] ${
          variant.backgroundClass
        } ${small ? "place-self-center" : "mt-[20px] xl:mt-[22px] self-start"}`}
      ></div>
      <IndexHighlightedContainer
        orange={orange}
        small={small}
        rightToLeft={rightToLeft}
      >
        {children}
      </IndexHighlightedContainer>
    </div>
  )
}
IndexPrompt.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  rightToLeft: PropTypes.bool,
  small: PropTypes.bool,
  orange: PropTypes.bool,
}
