import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

const TypedText = ({ id, cursor, textArray, className }) => {
  let typedTextSpan
  const typingDelay = 150
  const erasingDelay = 150
  const newTextDelay = 2000
  let textArrayIndex = 0
  let charIndex = 0
  className = className || "h-7"

  function type() {
    if (charIndex < textArray[textArrayIndex].length) {
      if (typedTextSpan && typedTextSpan.classList.contains("-animate"))
        typedTextSpan.classList.remove("-animate")
      if (typedTextSpan)
        typedTextSpan.innerHTML += textArray[textArrayIndex].charAt(charIndex)
      charIndex++
      setTimeout(type, typingDelay)
    } else {
      if (typedTextSpan) typedTextSpan.classList.add("-animate")
      setTimeout(erase, newTextDelay)
    }
  }

  function erase() {
    if (charIndex > 0) {
      if (typedTextSpan && typedTextSpan.classList.contains("-animate"))
        typedTextSpan.classList.remove("-animate")
      if (typedTextSpan)
        typedTextSpan.innerHTML = textArray[textArrayIndex].substring(
          0,
          charIndex - 1
        )
      charIndex--
      setTimeout(erase, erasingDelay)
    } else {
      if (typedTextSpan) typedTextSpan.classList.add("-animate")
      textArrayIndex++
      if (textArrayIndex >= textArray.length) textArrayIndex = 0
      setTimeout(type, typingDelay + 1100)
    }
  }

  function reset() {
    textArrayIndex = 0
    charIndex = 0
    if (typedTextSpan) typedTextSpan.innerHTML = ""
  }

  useEffect(() => {
    typedTextSpan = document.getElementById(id) /* eslint-disable-line */
    reset()
    if (textArray.length && typedTextSpan) {
      setTimeout(type, 250)
    }
  }, []) /* eslint-disable-line */

  return (
    <div className="flex">
      <span id={id} className={`typing__inner ${cursor ? cursor : ""}`}></span>

      <motion.div
        className={`w-4 mt-3 bg-white ${className}`}
        animate={{
          opacity: [0, 1, 0],
        }}
        transition={{
          duration: 0.8,
          repeat: Infinity,
        }}
      ></motion.div>
    </div>
  )
}

TypedText.propTypes = {
  id: PropTypes.string.isRequired,
  cursor: PropTypes.string,
  textArray: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default TypedText
